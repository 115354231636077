import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Select from "../customComponents/Select";
import Upload from "../customComponents/Upload";
import { toast, ToastContainer } from "react-toastify";
import DayPlan from "../customComponents/DayPlan";
import TextEditor from "../customComponents/TextEditor";
import DatePicker from "../customComponents/DatePicker";
import { post } from "../utils/api";
import MultiSelect from "../customComponents/MultiSelect";
import { MultiNewSelect } from "../customComponents/NewMultiSelect";

const AddPackages = () => {
  const [days, setDays] = useState([]);
  const [isFirstDayAdded, setIsFirstDayAdded] = useState(false);
  const [selectedValue, setSelectedValue] = useState("single");
  const [formData, setFormData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showSeasonalFields, setShowSeasonalFields] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Default to today's date
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [selectedOnwardTransport, setSelectedOnwardTransport] = useState(null);
  const [selectedReturnTransport, setSelectedReturnTransport] = useState(null);
  const [selectedTransfers, setSelectedTransfers] = useState(null);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [extraBFCount, setExtraBFCount] = useState("");
  const [extraLunchCount, setExtraLunchCount] = useState("");
  const [extraDinnerCount, setExtraDinnerCount] = useState("");
  const [numberOfPax, setNumberOfPax] = useState("");
  const [addOnsAmount, setAddOnsAmount] = useState("");
  const [deviationAmount, setDeviationAmount] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [packageTypeOptions, setPackageTypeOptions] = useState([]);
  const [hotelTypeOptions, setHotelTypeOptions] = useState([]);
  const [onwardTransportOptions, setOnwardTransportOptions] = useState([]);
  const [returnTransportOptions, setReturnTransportOptions] = useState([]);
  const [transferOptions, setTransferOptions] = useState([]);
  const [addonOptions, setAddonOptions] = useState([]);
  const [selectedPackageType, setSelectedPackageType] = useState(null);
  const [formValues, setFormValues] = useState({
    packagetypeid: null,
    packagename: "",
    city: "",
    destination: "",
    noofdays: "",
    noofnights: "",
    hoteltypeid: null,
    onwardtrnid: null,
    returnid: null,
    transferid: null,
    addonid: null,
    extra_bfcount: 0,
    extra_lunchcount: 0,
    extra_dinnercount: 0,
    noofpax: 0,
    foc: "",
    addon_amount: 0,
    deviation_amount: 0,
    price_perhead: 0,
    daywise_plan: [],
    package_image: null,
    currency: "INR",
    total_amount: 0,
  });
  const [dropdownDataLoaded, setDropdownDataLoaded] = useState(false);

  console.log(formValues?.daywise_plan, "daywise");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { packageData, type } = location.state || {};
  const isEdit = location.state?.isEdit;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await Promise.all([
  //       fetchPackageTypes(),
  //       fetchHotelTypes(),
  //       fetchOnwardTransport(),
  //       fetchReturnTransport(),
  //       fetchTransfers(),
  //       fetchAddons(),
  //     ]);
  //     setDropdownDataLoaded(true); // Indicate that data is fully loaded
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchAllOptions = async () => {
      try {
        await Promise.all([
          fetchPackageTypes(),
          fetchHotelTypes(),
          fetchOnwardTransport(),
          // fetchReturnTransport(),
          //fetchTransfers(),
          fetchAddons(),
        ]);
        setDropdownDataLoaded(true); // Mark options as loaded
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchAllOptions();
  }, []);

  useEffect(() => {
    if (isEdit) {
      prePopulateValue(); // Now dropdown options are ready
    }
  }, [dropdownDataLoaded]);

  const prePopulateValue = async () => {
    try {
      setShowSeasonalFields(packageData?.seasonal || false);
      setSelectedPackageType({
        label: packageData.packagetype,
        value: packageData.packagetype,
        id: packageData.packagetypeid,
      });
      setFormValues({
        packagename: packageData.packagename,
        city: packageData.city,
        destination: packageData.destination,
        noofdays: packageData.noofdays,
        noofnights: packageData.noofnights,
      });
      const hotelType = hotelTypeOptions.find(
        (hotel) => hotel?.id === parseInt(packageData?.hoteltypeid)
      );
      const onWardTrn = onwardTransportOptions.find(
        (list) => list?.id === parseInt(packageData?.onwardtrnid)
      );
      const returnWardTrn = onwardTransportOptions.find(
        (list) => list?.id === parseInt(packageData?.returnid)
      );
      const transfers = onwardTransportOptions.find(
        (list) => list?.id === parseInt(packageData?.transferid)
      );
      // const addons = addonOptions.find(
      //   (list) => list?.id === parseInt(packageData?.addonid)
      // );

      const addons = addonOptions.filter((list) =>
        packageData?.addonid.includes(list.id.toString())
      );

      const foc = options.find((list) => list?.value === packageData?.foc);

      setSelectedOnwardTransport(onWardTrn);
      setSelectedReturnTransport(returnWardTrn);
      setSelectedTransfers(transfers);
      setSelectedHotel(hotelType);
      setSelectedAddons(addons);
      setExtraBFCount(Number(packageData?.extra_bfcount));
      setExtraLunchCount(Number(packageData?.extra_lunchcount));
      setExtraDinnerCount(Number(packageData?.extra_dinnercount));
      setNumberOfPax(Number(packageData?.noofpax));
      setSelectedOption(foc);
      setAddOnsAmount(Number(packageData?.addon_amount));
      setDeviationAmount(Number(packageData?.deviation_amount));
      setEditorContents({
        transportation_details:
          packageData?.texteditordata?.transportation_details,
        hotel_details: packageData?.texteditordata?.hotel_details,
        meal_plan: packageData?.texteditordata?.meal_plan,
        inclusions: packageData?.texteditordata?.inclusions,
        exclusions: packageData?.texteditordata?.exclusions,
        terms_conditions: packageData?.texteditordata?.terms_conditions,
        learning_outcomes: packageData?.texteditordata?.learning_outcomes,
      });
      setSelectedDate(new Date(packageData?.dateoftravel));
      let isSeason = packageData?.dateoftravel ? true : false;
      setShowSeasonalFields(isSeason);
      setFormData({
        logo: packageData?.package_image ? packageData?.package_image : null,
      });
      //  setFormData(packageData?.package_image);
      // setDays(packageData?.daywise_plan)
    } catch (error) {}
  };

  const handleAddDay = useCallback(() => {
    setIsFirstDayAdded(true);
    setDays((prevDays) => {
      const newDayNumber = prevDays.length + 1;
      return [...prevDays, { dayNumber: newDayNumber, timeSlots: [] }];
    });
  }, []);
  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleUploadSelect = (file) => {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFormData({ ...formData, logo: reader.result });
    };
    reader.onerror = (error) => {
      console.error("Error converting file to Base64:", error);
    };
  };

  const validateFile = (file) => {
    if (!file.type.startsWith("image/")) {
      return "Only image files are allowed.";
    }
    if (file.size > 5 * 1024 * 1024) {
      return "File size should not exceed 5MB.";
    }
    return null;
  };
  const options = [
    { value: "10:1", label: "10:1" },
    { value: "20:1", label: "20:1" },
    { value: "30:1", label: "30:1" },
    { value: "40:1", label: "40:1" },
  ];

  const handleChange = (option) => {
    setSelectedOption(option);
    console.log("Selected option:", option);
  };

  // const hotelTypes = [
  //   { id: 1, label: "Karaikudi - 3 Star", pph: 1 },
  //   { id: 2, label: "Madurai - 5 Star", pph: 150 },
  //   { id: 3, label: "Coimbatore - 4 Star", pph: 120 },
  //   { id: 4, label: "Chennai - Budget", pph: 50 },
  // ];

  // const onwardTransportation = [
  //   { id: 1, label: "Flight", pph: 1 },
  //   { id: 2, label: "Train - Sleeper", pph: 1500 },
  //   { id: 3, label: "Bus - AC", pph: 1000 },
  //   { id: 4, label: "Private Car", pph: 2500 },
  // ];

  // const returnTransportation = [
  //   { id: 1, label: "Flight", pph: 1 },
  //   { id: 2, label: "Train - 2nd AC", pph: 2000 },
  //   { id: 3, label: "Bus - Non-AC", pph: 800 },
  //   { id: 4, label: "Self-drive Car", pph: 3000 },
  // ];

  // const addons = [
  //   { id: 1, label: "Extra Bed", pph: 1 },
  //   { id: 2, label: "City Tour Guide", pph: 1500 },
  //   { id: 3, label: "Adventure Package", pph: 2000 },
  //   { id: 4, label: "Meal Plan Upgrade", pph: 800 },
  // ];

  // const focOptions = [
  //   { id: 1, label: "10:1" },
  //   { id: 2, label: "15:1" },
  //   { id: 3, label: "20:1" },
  //   { id: 4, label: "25:1" },
  // ];
  // Add this function to calculate the total price
  const calculateTotalPrice = () => {
    if (!numberOfPax || numberOfPax <= 0) return 0;

    let totalPrice = 0;

    const isInSeason = (startDate, endDate) => {
      if (!startDate || !endDate || !selectedDate) return false;
      const currentDate = new Date(selectedDate);
      const seasonStart = new Date(startDate);
      const seasonEnd = new Date(endDate);
      return currentDate >= seasonStart && currentDate <= seasonEnd;
    };

    const getPrice = (item) => {
      if (!showSeasonalFields || !item) return Number(item?.pph || 0);
      if (isInSeason(item?.seasonstart, item?.seasonend) && item?.sph) {
        return Number(item?.sph);
      }
      return Number(item?.pph || 0);
    };

    // Calculate addons total with proper number conversion
    const addonsTotal = Array.isArray(selectedAddons)
      ? selectedAddons.reduce((sum, addon) => sum + Number(getPrice(addon)), 0)
      : 0;

    // Calculate base price with proper number conversion
    const basePrice =
      Number(getPrice(selectedHotel)) +
      Number(getPrice(selectedOnwardTransport)) +
      Number(getPrice(selectedReturnTransport)) +
      Number(getPrice(selectedTransfers)) +
      Number(addonsTotal) +
      Number(extraBFCount) +
      Number(extraLunchCount) +
      Number(extraDinnerCount);

    // Calculate per person price
    totalPrice = basePrice / Number(numberOfPax);

    // Apply FOC ratio if selected
    if (selectedOption) {
      const [ratio] = selectedOption.value.split(":").map(Number);
      totalPrice = (totalPrice * (ratio + 1)) / ratio;
    }

    // Add additional amounts
    totalPrice += Number(addOnsAmount) || 0;
    totalPrice += Number(deviationAmount) || 0;

    return Math.round(totalPrice);
  };

  // Modify the existing handlers and add new ones
  const handleHotelChange = (option) => {
    const selected = hotelTypeOptions.find(
      (hotel) => hotel.id === option.value
    );
    setSelectedHotel(selected);
  };

  const handleOnwardTransportChange = (option) => {
    const selected = onwardTransportOptions.find(
      (transport) => transport.id === option.value
    );
    setSelectedOnwardTransport(selected);
  };

  const handleReturnTransportChange = (option) => {
    const selected = returnTransportOptions.find(
      (transport) => transport.id === option.value
    );
    setSelectedReturnTransport(selected);
  };

  const handleTransfersChange = (option) => {
    const selected = transferOptions.find(
      (transport) => transport.id === option.value
    );
    setSelectedTransfers(selected);
  };

  const handleAddonsChange = (selectedOptions, type) => {
    setSelectedAddons(selectedOptions);
  };

  useEffect(() => {
    const newTotal = calculateTotalPrice();
    setTotalPrice(newTotal);
  }, [
    selectedHotel,
    selectedOnwardTransport,
    selectedReturnTransport,
    selectedTransfers,
    selectedAddons,
    extraBFCount,
    extraLunchCount,
    extraDinnerCount,
    numberOfPax,
    selectedOption,
    addOnsAmount,
    deviationAmount,
    days.length,
    showSeasonalFields,
    selectedDate,
  ]);
  const fetchPackageTypes = async () => {
    try {
      const payload = { type: "packagetype" };
      const result = await post("/getOptionList", payload);
      const options = result.data.map((item) => ({
        value: item.value,
        label: item.label,
        id: item.value,
      }));
      setPackageTypeOptions(options);
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  const fetchHotelTypes = async () => {
    try {
      const payload = { type: "hotelname_hoteltype" };
      const result = await post("/getOptionList", payload);
      // const options = result.data.map((item) => ({
      //   value: item.label,
      //   label: item.label,
      //   pph: item.pph,
      //   id: item.id
      // }));
      setHotelTypeOptions(result.data);
    } catch (error) {
      console.error("Error fetching hotel types:", error);
    }
  };

  const fetchOnwardTransport = async () => {
    try {
      const payload = { type: "vehicaltype_vehicalname" };
      const result = await post("/getOptionList", payload);
      // const options = result.data.map((item) => ({
      //   value: item.id,
      //   label: item.label,
      //   pph: item.pph,
      // }));
      setOnwardTransportOptions(result.data);
      setReturnTransportOptions(result.data);
      setTransferOptions(result.data);
    } catch (error) {
      console.error("Error fetching onward transport:", error);
    }
  };

  const fetchReturnTransport = async () => {
    try {
      const payload = { type: "vehicaltype_vehicalname" };
      const result = await post("/getOptionList", payload);
      const options = result.data.map((item) => ({
        value: item.id,
        label: item.label,
        pph: item.pph,
      }));
      setReturnTransportOptions(options);
    } catch (error) {
      console.error("Error fetching return transport:", error);
    }
  };

  const fetchTransfers = async () => {
    try {
      const payload = { type: "vehicaltype_vehicalname" };
      const result = await post("/getOptionList", payload);
      const options = result.data.map((item) => ({
        value: item.id,
        label: item.label,
        pph: item.pph,
      }));
      setTransferOptions(options);
    } catch (error) {
      console.error("Error fetching transfers:", error);
    }
  };

  const fetchAddons = async () => {
    try {
      const payload = { type: "addons_price" };
      const result = await post("/getOptionList", payload);
      // const options = result.data.map((item) => ({
      //   value: item.id,
      //   label: item.label,
      //   pph: item.pph,
      // }));
      setAddonOptions(result.data);
    } catch (error) {
      console.error("Error fetching addons:", error);
    }
  };
  // Add handlers for form inputs
  const handlePackageTypeChange = (option) => {
    setSelectedPackageType(option);
    setFormValues((prev) => ({ ...prev, packagetypeid: option.value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSavePackage = async () => {
    try {
      const formattedDays = days.map((day) => ({
        dayNumber: day.dayNumber,
        timeSlots: day.timeSlots || [],
      }));

      const addonIds = Array.isArray(selectedAddons)
        ? `{${selectedAddons.map((addon) => addon.id).join(",")}}`
        : selectedAddons?.id
        ? `{${selectedAddons.id}}`
        : null;

      const basePayload = {
        packagetypeid: selectedPackageType?.id,
        packagename: formValues.packagename,
        cityid: formValues.city,
        destination: formValues.destination,
        noofdays: Number(formValues.noofdays),
        noofnights: Number(formValues.noofnights),
        hoteltypeid: selectedHotel?.id,
        onwardtrnid: selectedOnwardTransport?.id,
        returnid: selectedReturnTransport?.id,
        transferid: selectedTransfers?.id,
        addonid: addonIds,
        extra_bfcount: Number(extraBFCount),
        extra_lunchcount: Number(extraLunchCount),
        extra_dinnercount: Number(extraDinnerCount),
        noofpax: Number(numberOfPax),
        foc: selectedOption?.value,
        addon_amount: Number(addOnsAmount),
        deviation_amount: Number(deviationAmount),
        price_perhead: Number(totalPrice),
        daywise_plan: formattedDays,
        package_image: formData?.logo ? formData?.logo : "",
        currency: "INR",
        total_amount: Number(totalPrice * numberOfPax),
        type: location.state?.isEdit ? "update" : "insert",
        textEditorData: editorContents,
        city: formValues.city,
        dateoftravel: selectedDate,
      };

      const payload = location.state?.isEdit
        ? {
            ...basePayload,
            packagedetailsid: location.state?.packageData?.packagedetailsid,
          }
        : basePayload;

      const response = await post("/addPackage", payload);

      if (response.status) {
        toast.success("Package added successfully!");
        navigate("/all-packages");
      }
    } catch (error) {
      toast.error("Failed to save package: " + error.message);
    }
  };

  const handleConvertToItinerary = () => {
    const packageName = formValues.packagename || "Package";
    toast.success(`${packageName} saved as draft`);
  };
  const [editorContents, setEditorContents] = useState({
    transportation_details: "",
    hotel_details: "",
    meal_plan: "",
    inclusions: "",
    exclusions: "",
    terms_conditions: "",
    learning_outcomes: "",
  });

  const handleEditorChange = (content, field) => {
    setEditorContents((prev) => ({
      ...prev,
      [field]: content,
    }));
  };
  const handleDayPlanChange = useCallback((dayNumber, planData) => {
    setDays((prevDays) => {
      const updatedDays = [...prevDays];
      const dayIndex = updatedDays.findIndex(
        (day) => day.dayNumber === dayNumber
      );

      if (dayIndex !== -1) {
        updatedDays[dayIndex] = {
          dayNumber,
          timeSlots: planData.timeSlots,
        };
      }
      return updatedDays;
    });
  }, []);
  // useEffect(() => {
  //   if (
  //     isEdit &&
  //     packageTypeOptions.length > 0 &&
  //     hotelTypeOptions.length > 0 &&
  //     onwardTransportOptions.length > 0 &&
  //     returnTransportOptions.length > 0 &&
  //     transferOptions.length > 0 &&
  //     addonOptions.length > 0
  //   ) {
  //     // Set Package Type
  //     const packageType = packageTypeOptions.find(
  //       (option) => option.value === isEdit.packagetypeid
  //     );
  //     setSelectedPackageType(packageType);

  //     // Set Hotel Type
  //     const hotelType = hotelTypeOptions.find(
  //       (option) => option.value === Number(isEdit.hoteltypeid)
  //     );
  //     setSelectedHotel(hotelType);

  //     // Set Onward Transport
  //     const onwardTransport = onwardTransportOptions.find(
  //       (option) => option.value === Number(isEdit.onwardtrnid)
  //     );
  //     setSelectedOnwardTransport(onwardTransport);

  //     // Set Return Transport
  //     const returnTransport = returnTransportOptions.find(
  //       (option) => option.value === Number(isEdit.returnid)
  //     );
  //     setSelectedReturnTransport(returnTransport);

  //     // Set Transfers
  //     const transfer = transferOptions.find(
  //       (option) => option.value === Number(isEdit.transferid)
  //     );
  //     setSelectedTransfers(transfer);

  //     // Set Addons if exists
  //     if (isEdit.addonid) {
  //       const addon = addonOptions.find(
  //         (option) => option.value === Number(isEdit.addonid)
  //       );
  //       setSelectedAddons(addon);
  //     }

  //     // Set FOC
  //     if (isEdit.foc) {
  //       setSelectedOption({ value: isEdit.foc, label: isEdit.foc });
  //     }
  //   }
  // }, [
  //   isEdit,
  //   packageTypeOptions,
  //   hotelTypeOptions,
  //   onwardTransportOptions,
  //   returnTransportOptions,
  //   transferOptions,
  //   addonOptions,
  // ]);

  const calculateAddonAmount = useCallback(() => {
    if (!selectedAddons || selectedAddons.length === 0) return 0;

    const totalAmount = selectedAddons.reduce((sum, addon) => {
      // Check if we're using seasonal pricing
      if (showSeasonalFields && addon.seasonstart && addon.seasonend) {
        const seasonStart = new Date(addon.seasonstart);
        const seasonEnd = new Date(addon.seasonend);
        const currentDate = new Date(selectedDate);

        // If date falls within seasonal period and sph exists, use seasonal price
        if (
          currentDate >= seasonStart &&
          currentDate <= seasonEnd &&
          addon.sph
        ) {
          return sum + Number(addon.sph);
        }
      }
      // Default to regular price
      return sum + Number(addon.pph);
    }, 0);

    return totalAmount;
  }, [selectedAddons, showSeasonalFields, selectedDate]);

  useEffect(() => {
    const calculatedAmount = calculateAddonAmount();
    setAddOnsAmount(calculatedAmount);
  }, [selectedAddons, showSeasonalFields, selectedDate, calculateAddonAmount]);

  return (
    <div>
      <div className="block md:block lg:flex justify-between">
        <div>
          <h2 className="text-primary-black text-[24px] font-semibold">
            Packages
          </h2>
          <p className="text-[12px]">
            <span className="font-bold">Packages</span> /
            <span className="font-regular">Add Package</span>
          </p>
        </div>
        <div className="flex gap-4 mt-4 md:mt-0 justify-end">
          <Button
            label="Convert To Itinerary"
            onClick={handleConvertToItinerary}
          />{" "}
          <Button
            label={
              <span className="flex items-center">
                Export PDF
                <span
                  className="material-symbols-outlined ml-2"
                  style={{
                    fontVariationSettings:
                      "'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24",
                  }}
                >
                  picture_as_pdf
                </span>
              </span>
            }
          />
          {selectedValue === "single" && (
            <Button
              type="primary"
              label={isEdit ? "Update" : "Save"}
              onClick={handleSavePackage}
            />
          )}
          <Button
            type="secondary"
            label="Cancel"
            onClick={handleNavigateBack}
          />
        </div>
      </div>
      <Paper className="my-8 pt-6 pb-6">
        <Paper className="my-8 border">
          <h1 className="text-[20px] font-bold mb-4">Package Details</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Package Type
                </label>
                <Select
                  options={packageTypeOptions}
                  onChange={handlePackageTypeChange}
                  value={selectedPackageType}
                  placeholder="Select Package Type..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold">
                  Package Name
                </label>
                <InputText
                  placeholder="Package Name..."
                  name="packagename"
                  value={formValues.packagename}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">Source</label>
                <InputText
                  name="city"
                  value={formValues.city}
                  onChange={handleInputChange}
                  placeholder="Enter source..."
                />{" "}
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Destination
                </label>
                <InputText
                  name="destination"
                  value={formValues.destination}
                  onChange={handleInputChange}
                  placeholder="Enter destination..."
                />{" "}
              </div>
              <div>
                <label className="text-[16px] font-semibold">No. of Days</label>
                <InputText
                  value={formValues.noofdays}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      noofdays: e.target.value,
                    }))
                  }
                  placeholder="Enter Days Count..."
                />{" "}
              </div>
              <div>
                <label className="text-[16px] font-semibold">
                  No. of Nights
                </label>
                <InputText
                  value={formValues.noofnights}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      noofnights: e.target.value,
                    }))
                  }
                  placeholder="Enter Nights Count..."
                />{" "}
              </div>
            </div>

            <div className="mt-3">
              <Upload
                onFileSelect={handleUploadSelect}
                labelText="Upload Image"
                initialPreviewUrl={formData?.logo ? formData?.logo : null}
                fileValidation={validateFile}
                handleRemove={() => {
                  setFormData(null);
                }}
              />
            </div>
          </div>
        </Paper>
        <Paper className="my-8 border">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="col-span-8 grid grid-cols-6 md:grid-cols-6 gap-1 mt-1 items-center">
              {/* Seasonal Checkbox and Label */}
              <div className="flex items-center gap-1">
                <input
                  type="checkbox"
                  id="seasonalPrice"
                  checked={showSeasonalFields}
                  onChange={(e) => setShowSeasonalFields(e.target.checked)}
                  className="mr-1"
                />
                <label
                  htmlFor="seasonalPrice"
                  className="text-[14px] font-semibold"
                >
                  Seasonal
                </label>
              </div>

              {/* Display seasonal fields when checkbox is checked */}
              {showSeasonalFields && (
                <div className="flex items-center gap-1">
                  <div>
                    <label className="text-[14px] font-semibold mb-0">
                      Date Of Travel
                    </label>
                    <DatePicker
                      selectedDate={selectedDate}
                      onDateChange={(date) => handleDateChange(date)}
                      placeholderText="Start Date"
                      className="w-full mt-0"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Main Form Fields */}
            <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Hotel Type
                </label>
                <Select
                  options={hotelTypeOptions}
                  onChange={handleHotelChange}
                  value={hotelTypeOptions.find(
                    (option) => option.value === selectedHotel?.id
                  )}
                  placeholder="Select Hotel Type..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold">
                  Onward Transportation
                </label>
                <Select
                  options={onwardTransportOptions}
                  value={onwardTransportOptions.find(
                    (option) => option.value === selectedOnwardTransport?.id
                  )}
                  onChange={handleOnwardTransportChange}
                  placeholder="Select Onward Transportation..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Return Transportation
                </label>
                <Select
                  options={returnTransportOptions}
                  value={returnTransportOptions.find(
                    (option) => option.value === selectedReturnTransport?.id
                  )}
                  onChange={handleReturnTransportChange}
                  placeholder="Select Return Transportation..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Transfers
                </label>
                <Select
                  options={transferOptions}
                  value={transferOptions.find((option) => {
                    return option.value === selectedTransfers?.id;
                  })}
                  onChange={handleTransfersChange}
                  placeholder="Select Transfers..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold">Addons</label>
                <MultiNewSelect
                  // className="form-control"
                  placeHolder="Select Addons..."
                  options={addonOptions}
                  // name="fileTypes"
                  value={selectedAddons}
                  onChange={(e) => handleAddonsChange(e, "addons")}
                  isMulti
                  id="fileTypes"
                />
                {/* <MultiSelect
                  options={addonOptions}
                  value={selectedAddons}
                  onChange={handleAddonsChange}
                  placeholder="Select Addons..."
                /> */}
              </div>

              <div>
                <label className="text-[16px] font-semibold">
                  Extra BF Count
                </label>
                <InputText
                  placeholder="Enter Count..."
                  value={extraBFCount}
                  onChange={(e) => setExtraBFCount(Number(e.target.value))}
                />{" "}
              </div>
              <div>
                <label className="text-[16px] font-semibold">
                  Extra Lunch Count
                </label>
                <InputText
                  placeholder="Enter Count..."
                  value={extraLunchCount}
                  onChange={(e) => setExtraLunchCount(Number(e.target.value))}
                />{" "}
              </div>
              <div>
                <label className="text-[16px] font-semibold">
                  Extra Dinner Count
                </label>
                <InputText
                  placeholder="Enter Count..."
                  value={extraDinnerCount}
                  onChange={(e) => setExtraDinnerCount(Number(e.target.value))}
                />{" "}
              </div>
              <div>
                <label className="text-[16px] font-semibold">No. of Pax</label>
                <InputText
                  placeholder="Enter number..."
                  value={numberOfPax}
                  onChange={(e) => setNumberOfPax(Number(e.target.value))}
                />{" "}
              </div>
              <div>
                <label className="text-[16px] font-semibold">FOC</label>
                <Select
                  options={options}
                  value={selectedOption}
                  onChange={handleChange}
                  placeholder="Select FOC..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold">
                  Add Ons Calculated Amount
                </label>
                <InputText
                  placeholder="Enter Amount..."
                  value={addOnsAmount}
                  onChange={(e) => setAddOnsAmount(Number(e.target.value))}
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold">
                  Deviation Allowed Amount
                </label>
                <InputText
                  placeholder="Enter Amount..."
                  value={deviationAmount}
                  onChange={(e) => setDeviationAmount(Number(e.target.value))}
                />{" "}
              </div>
            </div>

            {/* Price Box */}
            <div className="bg-[#CDD8CB] p-6 rounded-lg max-w-sm mx-auto text-left">
              <span className="material-symbols-outlined text-gray-700 text-3xl mb-2">
                payments
              </span>
              <div className="text-gray-700 text-sm mb-2">
                Price/ head (INR)
              </div>
              <div className="text-4xl font-bold text-black mb-4">
                {" "}
                ₹ {totalPrice.toLocaleString("en-IN")}
              </div>
              <div className="text-xs text-gray-600">
                **Above number is based on the head count and other add-ons
                entered, this may change based on the count change.
              </div>
            </div>
          </div>
        </Paper>

        <div className="bg-white rounded-xl border-gray-300 p-4 my-8 border">
          <h1 className="text-[20px] font-bold mb-4">Day-wise Planning</h1>
          <div className="grid grid-cols-1 gap-4">
            {!isFirstDayAdded ? (
              <Paper className="border-2 border-dashed border-black p-8  flex justify-center items-center flex-col">
                <button
                  onClick={handleAddDay}
                  className=" bg-white text-black border border-black rounded-md py-2 px-12 text-lg mb-4 mt-4"
                >
                  Add Day
                </button>
                <p className="text-center text-gray-500 mt-4">
                  No day plan to display
                </p>
              </Paper>
            ) : (
              <div className="grid grid-cols-1  lg:grid-cols-2 gap-4 ">
                {days.map((day, index) => (
                  <div key={index} className="w-full">
                    <DayPlan
                      dayNumber={day.dayNumber}
                      onChange={(planData) =>
                        handleDayPlanChange(day.dayNumber, planData)
                      }
                    />{" "}
                  </div>
                ))}
                <Paper className="border-2 border-dashed border-black p-8  flex justify-center items-center flex-col">
                  <button
                    onClick={handleAddDay}
                    className=" bg-white text-black border border-black rounded-md py-2 px-12 text-lg"
                  >
                    Add Day
                  </button>
                </Paper>
              </div>
            )}
          </div>
        </div>
        <div className="container mx-auto px-4 py-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Transportation Details
              </label>
              <TextEditor
                initialValue={editorContents.transportation_details}
                onChange={(content) =>
                  handleEditorChange(content, "transportation_details")
                }
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Hotel Details
              </label>
              <TextEditor
                initialValue={editorContents.hotel_details}
                onChange={(content) =>
                  handleEditorChange(content, "hotel_details")
                }
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Meal Plan
              </label>
              <TextEditor
                initialValue={editorContents.meal_plan}
                onChange={(content) => handleEditorChange(content, "meal_plan")}
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Inclusions
              </label>
              <TextEditor
                initialValue={editorContents.inclusions}
                onChange={(content) =>
                  handleEditorChange(content, "inclusions")
                }
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Exclusions
              </label>
              <TextEditor
                initialValue={editorContents.exclusions}
                onChange={(content) =>
                  handleEditorChange(content, "exclusions")
                }
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Terms and Conditions
              </label>
              <TextEditor
                initialValue={editorContents.terms_conditions}
                onChange={(content) =>
                  handleEditorChange(content, "terms_conditions")
                }
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Learning Outcomes
              </label>
              <TextEditor
                initialValue={editorContents.learning_outcomes}
                onChange={(content) =>
                  handleEditorChange(content, "learning_outcomes")
                }
              />
            </div>
          </div>
        </div>
      </Paper>
      <ToastContainer />
    </div>
  );
};

export default AddPackages;
