import React from "react";
import PropTypes from "prop-types";

const Time = ({ value, onChange, ...props }) => {
  return (
    <input
      type="time"
      value={value}
      onChange={onChange}
      className="border-b-2 bg-white border-gray-300 rounded-md w-full mt-2 text-sm p-2 placeholder-text-sm focus:outline-none focus:border-blue-500"
      {...props}
    />
  );
};

Time.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Time.defaultProps = {
  value: "00:00", // Default to 00:00 format (hours and minutes only)
};

export default Time;
