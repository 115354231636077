import React, { useState, useEffect } from "react";
import Button from "../customComponents/Button";
import Table from "../customComponents/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { post } from "../utils/api";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../customComponents/ConfirmationModal";

const Addons = () => {
  const [addonsData, setAddonsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  const navigate = useNavigate();

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("slNo", {
      header: "Sl. No",
      cell: (info) => info.row.index + 1,
    }),
    columnHelper.accessor("addonitem", {
      header: "ADDON ITEM",
    }),
    columnHelper.accessor(
      (row) => {
        return row.addonprice === "null"
          ? row.season_priceperhead
          : row.addonprice;
      },
      {
        header: "PRICE PER HEAD",
        id: "priceperhead",
        cell: (info) => `₹ ${info.getValue()}`,
      }
    ),
    columnHelper.accessor(
      (row) => {
        if (row.seasonstart && row.seasonend) {
          const startDate = new Date(row.seasonstart).toLocaleDateString();
          const endDate = new Date(row.seasonend).toLocaleDateString();
          return `${startDate} - ${endDate}`;
        }
        return "N/A";
      },
      {
        header: "SEASON",
        id: "season",
      }
    ),
    columnHelper.accessor("actions", {
      header: "ACTIONS",
      cell: (info) => (
        <div className="flex gap-2">
          <button
            className="p-2 bg-gray-200 rounded-md"
            onClick={() =>
              navigate("/add-addons", {
                state: { editData: info.row.original },
              })
            }
          >
            <span className="material-symbols-outlined">edit</span>
          </button>
          <button
            className="p-2 bg-gray-200 text-red-500 rounded-md"
            onClick={() => handleDeleteClick(info.row.original)}
          >
            <span className="material-symbols-outlined">delete</span>
          </button>
        </div>
      ),
    }),
  ];

  const fetchAddons = async () => {
    try {
      const response = await post("/getAllAddons", {});
      setAddonsData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching addons:", error);
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);

    const filtered = addonsData.filter(
      (addon) =>
        addon.addonitem.toLowerCase().includes(searchValue) ||
        (addon.addonprice &&
          addon.addonprice.toString().includes(searchValue)) ||
        (addon.season_priceperhead &&
          addon.season_priceperhead.toString().includes(searchValue))
    );

    setFilteredData(filtered);
  };

  const handleDeleteClick = (item) => {
    setDeleteItem(item);
    setIsDeleteModal(true);
  };

  const handleCloseModal = () => {
    setIsDeleteModal(false);
    setDeleteItem(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await post("/deleteAddon", { addonid: deleteItem.addonid });
      fetchAddons(); // Refresh the list
      handleCloseModal();
    } catch (error) {
      console.error("Error deleting addon:", error);
    }
  };

  useEffect(() => {
    fetchAddons();
  }, []);

  return (
    <div>
      <div className="block md:block lg:flex justify-between">
        <div>
          <h2 className="text-primary-black text-[28px] font-semibold">
            Addons
          </h2>
          <p className="text-[14px]">
            <span className="font-bold">Master Data</span> /
            <span className="font-regular">Addons</span>
          </p>
        </div>
        <div className="flex mt-4 justify-end gap-4">
          <Button
            type="primary"
            label="Add Addons"
            onClick={() => navigate("/add-addons")}
          />
        </div>
      </div>

      <div className="flex flex-wrap gap-4 mt-4">
        <input
          type="text"
          className="outline-none p-2 rounded-l-lg w-full sm:w-5/12 h-[40px]"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <ConfirmationModal
        isOpen={isDeleteModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        title="Delete Addon"
        message={`Are you sure you want to delete the Addon "${deleteItem?.addonitem}"?`}
      />
      <div className="bg-white rounded-xl border-gray-300 mt-7">
        <Table columns={columns} data={filteredData} />
      </div>
    </div>
  );
};

export default Addons;
