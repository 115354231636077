import Button from "../customComponents/Button";

const HotelModal = ({ onClose, hotelData }) => {
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString();
  };
  const handleEditClick = () => {
    navigate("/add-hotel", {
      state: {
        hotelinfo: info.row.original,
        type: "edit",
        editflag: true,
      },
    });
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-t-lg shadow-lg w-full h-[50vh] md:h-[75vh] overflow-y-auto p-6 border-b mt-auto">
        <div className="flex justify-between items-center mb-6 border-b">
          <h2 className="text-xl font-semibold">Hotel Details</h2>
          <div className="flex space-x-4 items-center">
            <Button
              type="primary"
              label="Edit Hotel"
              onClick={handleEditClick}
            />
            <a
              onClick={onClose}
              className="text-gray-500 hover:text-black cursor-pointer"
            >
              <span className="material-symbols-outlined">close</span>
            </a>
          </div>
        </div>

        <div className="grid grid-cols-6 gap-4 text-sm sm:text-base border-b">
          <div className="font-semibold col-span-1">Hotel Name</div>
          <div className="font-semibold col-span-1">Hotel Type</div>
          <div className="font-semibold col-span-1">City (Destination)</div>
          <div className="font-semibold col-span-1">Accommodation Type</div>
          <div className="font-semibold col-span-1">Grade</div>
          <div className="font-semibold col-span-1">Meal Plan</div>

          <div className="col-span-1">{hotelData.hotelName}</div>
          <div className="col-span-1">{hotelData.roomType}</div>
          <div className="col-span-1">{hotelData.city}</div>
          <div className="col-span-1">{hotelData.accommodation}</div>
          <div className="col-span-1">A</div>
          <div className="col-span-1">Standard</div>

          <div className="font-semibold col-span-2">Price per head (INR)</div>
          <div className="font-semibold col-span-2">Season</div>
          <div className="font-semibold col-span-2">
            Seasonal Price per head (INR)
          </div>

          <div className="col-span-2">{hotelData.price}</div>
          <div className="col-span-2">
            {" "}
            {formatDate(hotelData.seasonstart)} -{" "}
            {formatDate(hotelData.seasonend)}
          </div>
          <div className="col-span-2">{hotelData.season_priceperhead}</div>
        </div>
      </div>
    </div>
  );
};

export default HotelModal;
