import React, { useEffect, useCallback } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import AdminDashboard from "./components/AdminDashboard";
import AddInstitution from "./components/AddInstitution";
import AddEnquiry from "./components/AddEnquiry";
import Institutions from "./components/Institutions";
import Users from "./components/Users";
import Profile from "./components/Profile";
import Layout from "./layout";
import AddUser from "./components/AddUser";
import AllUser from "./components/AllUser";
import InstitutionDetails from "./components/InstitutionDetails";
import ResetPassword from "./components/ResetPassword";
import PrivateRoute from "./route/PrivateRoute";
import PublicRoute from "./route/PublicRoute";
import AddPackages from "./components/AddPackages";
import AllPackages from "./components/AllPackages";
import Hotels from "./components/Hotels";
import AddHotel from "./components/AddHotel";
import Transportations from "./components/Transportations";
import AddTransportation from "./components/AddTransportation";
import Addons from "./components/Addons";
import AddAddons from "./components/AddAddons ";
// const SESSION_TIMEOUT = 1 * 10 * 1000; // 1 minute in milliseconds
const SESSION_TIMEOUT = 8 * 60 * 60 * 1000;

function App() {
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    // Clear local storage
    localStorage.removeItem("user");
    navigate("/login"); // Redirect to login page
  }, [navigate]);

  useEffect(() => {
    const logoutTimer = setTimeout(handleLogout, SESSION_TIMEOUT);

    const resetTimeout = () => {
      clearTimeout(logoutTimer);
      setTimeout(handleLogout, SESSION_TIMEOUT);
    };

    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("keydown", resetTimeout);

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
    };
  }, [handleLogout]);

  return (
    <Routes>
      <Route path="login" element={<PublicRoute element={Login} />} />
      <Route
        path="reset-password/:optionalParam"
        element={<PublicRoute element={ResetPassword} />}
      />
      <Route
        path="forgot-password"
        element={<PublicRoute element={ForgotPassword} />}
      />
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="login" replace />} />
        <Route
          path="institutions"
          element={<PrivateRoute element={Institutions} />}
        />
        <Route
          path="institution-details"
          element={<PrivateRoute element={InstitutionDetails} />}
        />
        <Route
          path="dashboard"
          element={<PrivateRoute element={AdminDashboard} />}
        />
        <Route
          path="add-institutions"
          element={<PrivateRoute element={AddInstitution} />}
        />
        <Route
          path="add-enquiry"
          element={<PrivateRoute element={AddEnquiry} />}
        />
        <Route path="users" element={<PrivateRoute element={Users} />} />
        <Route path="profile" element={<PrivateRoute element={Profile} />} />
        <Route path="add-user" element={<PrivateRoute element={AddUser} />} />
        <Route path="all-user" element={<PrivateRoute element={AllUser} />} />
        <Route
          path="add-packages"
          element={<PrivateRoute element={AddPackages} />}
        />
        <Route
          path="all-packages"
          element={<PrivateRoute element={AllPackages} />}
        />
        <Route path="hotels" element={<PrivateRoute element={Hotels} />} />
        <Route path="add-hotel" element={<PrivateRoute element={AddHotel} />} />
        <Route
          path="transportation"
          element={<PrivateRoute element={Transportations} />}
        />
        <Route
          path="add-transportation"
          element={<PrivateRoute element={AddTransportation} />}
        />
        <Route path="addons" element={<PrivateRoute element={Addons} />} />
        <Route
          path="add-addons"
          element={<PrivateRoute element={AddAddons} />}
        />
      </Route>
    </Routes>
  );
}

export default App;
