import React from "react";
import Button from "../customComponents/Button";
import { useNavigate } from "react-router-dom";

const TransportationModal = ({ onClose, transportationData }) => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString();
  };
  const handleEditClick = () => {
    navigate("/add-transportation", {
      state: {
        transportData: transportationData,
        isEdit: true,
      },
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-t-lg shadow-lg w-full h-[50vh] md:h-[75vh] overflow-y-auto p-6 border-b mt-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-6 border-b">
          <h2 className="text-xl font-semibold">Vehicle Details</h2>
          <div className="flex space-x-4 items-center">
            <Button
              type="primary"
              label="Edit Vehicle"
              onClick={handleEditClick}
            />
            <a
              onClick={onClose}
              className="text-gray-500 hover:text-black cursor-pointer"
            >
              <span className="material-symbols-outlined">close</span>
            </a>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-6 gap-4 text-sm sm:text-base pb-4">
            <div className="font-semibold">Vehicle Name</div>
            <div className="font-semibold">Vehicle Type</div>
            <div className="font-semibold">AC/Non AC</div>
            <div className="font-semibold">No. of Seats</div>
            <div className="font-semibold">Source</div>
            <div className="font-semibold">Destination</div>

            <div>{transportationData.vehiclename}</div>
            <div>{transportationData.vehicletype}</div>
            <div>{transportationData.actype}</div>
            <div>{transportationData.noofseats}</div>
            <div>{transportationData.city || "N/A"}</div>
            <div>{transportationData.destination || "N/A"}</div>
          </div>
          <div className="grid grid-cols-6 gap-4 text-sm sm:text-base pb-4 mt-4">
            <div className="font-semibold">Local</div>
            <div className="font-semibold">Base KM</div>
            <div className="font-semibold">Base Charge (INR)</div>
            <div className="font-semibold">Additional KM</div>
            <div className="font-semibold">Additional Charge (INR)</div>

            <div>{transportationData.localinr || "N/A"}</div>
            <div>{transportationData.basekm || "N/A"}</div>
            <div>{transportationData.basechargeinr || "N/A"}</div>
            <div>{transportationData.additionalkm || "N/A"}</div>
            <div>{transportationData.additionalchargeinr || "N/A"}</div>
          </div>
          <div className="grid grid-cols-6 gap-4 text-sm sm:text-base pb-4 mt-4">
            <div className="font-semibold">Price Per Head</div>
            <div className="font-semibold">Interstate Tax</div>
            <div className="font-semibold">BATA</div>
            <div className="font-semibold">Parking</div>
            <div className="font-semibold">Season</div>
            <div></div>

            <div>{transportationData.priceperhead || "N/A"}</div>
            <div>{transportationData.interstatetax || "N/A"}</div>
            <div>{transportationData.batainr || "N/A"}</div>
            <div>{transportationData.parking || "N/A"}</div>
            <div>
              {formatDate(transportationData.seasonstart)} -{" "}
              {formatDate(transportationData.seasonend)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransportationModal;
