import PropTypes from "prop-types";

const InputText = ({
  placeholder,
  value,
  onChange,
  type,
  name,
  label,
  ...props
}) => {
  return (
    <div>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      {/* <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder || `Enter ${label}`}
        className="border-b-2 bg-white border text-mute-text border-gray-300 rounded-md h-[32px] w-full mt-2 text-sm p-2 placeholder:text-sm border-b-[#797979]"
        {...props}
      /> */}
<input 
  type={type}
  name={name}
  value={value}
  onChange={onChange}
  placeholder={placeholder || `Enter ${label}`}
  className="bg-white border border-gray-300 rounded-md h-[32px] w-full mt-2 text-sm p-2 placeholder:text-sm focus:border-gray-300 focus:border-b-2 focus:border-b-[#797979] focus:outline-none"
  {...props}
/>





    </div>
  );
};

InputText.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

InputText.defaultProps = {
  type: "text",
  name: "",
};

export default InputText;
