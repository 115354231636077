import React, { useEffect, useState } from "react";
import { post } from "../utils/api";
import FilterSelect from "../customComponents/FilterSelect";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import Button from "../customComponents/Button";
import Table from "../customComponents/Table";
import ConfirmationModal from "../customComponents/ConfirmationModal";
import { ToastContainer, toast } from "react-toastify";

const AllPackages = () => {
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [searchText, setSearchData] = useState("");
  const [packageOptions, setPackageOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [destOptions, setDestinationOptions] = useState([]);
  const [packageValue, setPackageValue] = useState("");
  const [city, setCity] = useState("");
  const [destination, setDestination] = useState("");

  const fetchPackages = async () => {
    try {
      const response = await post("/getAllPackages", {});
      if (response.status && response.data) {
        const formattedData = response.data.map((pkg) => ({
          packageName: pkg.packagename,
          packageType: pkg.packagetype,
          source: pkg.city,
          destination: pkg.destination || "-",
          pricePerHead: `₹ ${pkg.price_perhead}`,
          ...pkg,
        }));

        // Extract unique city and hotel options
        const packageSet = new Set(
          response.data.map((item) => item.packagetype)
        );
        const citySet = new Set(response.data.map((item) => item.city));
        const destinationSet = new Set(
          response.data.map((item) => item.destination)
        );

        const cityArray = [
          { label: "All City", value: "All City" },
          ...Array.from(citySet).map((city) => ({
            label: city,
            value: city,
          })),
        ];

        const pckArray = [
          { label: "All Package", value: "All Package" },
          ...Array.from(packageSet).map((hotel) => ({
            label: hotel,
            value: hotel,
          })),
        ];

        const destinationArray = [
          { label: "All Destination", value: "All Destination" },
          ...Array.from(destinationSet).map((destination) => ({
            label: destination,
            value: destination,
          })),
        ];

        // Update states
        setCityOptions(cityArray);
        setPackageOptions(pckArray);
        setDestinationOptions(destinationArray);

        setPackages(formattedData);
        setInitialData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);
  useEffect(() => {
    const filteredData = initialData.filter((item) => {
      // Convert values to lowercase for case-insensitive comparison
      const matchesSearch =
        !searchText || // Allow all if searchText is empty
        item.packagename?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.city?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.packagetype?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.destination?.toLowerCase().includes(searchText.toLowerCase()) ||
        `${item.price_perhead}`
          .toLowerCase()
          .includes(searchText.toLowerCase());

      const matchesCity =
        !city || city.value === "All City" || item.city === city.value;
      const matchesPackage =
        !packageValue ||
        packageValue.value === "All Package" ||
        item.packagetype === packageValue.value;
      const matchesDestination =
        !destination ||
        destination.value === "All Destination" ||
        item.destination === destination.value;

      // Combine filters, allowing partial matches if others are not applied
      return (
        matchesSearch && matchesCity && matchesPackage && matchesDestination
      );
    });

    setPackages(filteredData);
  }, [searchText, city, packageValue, destination]);

  const handleDeleteClick = async (data) => {
    setDeleteModal(true);
    setDeleteItem(data);
  };

  const handleCloseModal = async () => {
    setDeleteModal(false);
  };
  const handleConfirmDelete = async () => {
    try {
      const result = await post("/deletePackage", deleteItem);
      toast.success("Package deleted successfully");
      setDeleteModal(false);
      fetchPackages();
    } catch (error) {
      toast.error("Package delete failed");
      setDeleteModal(false);
    }
  };
  const columnHelper = createColumnHelper();
  const PackageTypeIcon = ({ type }) => {
    let icon = "";
    let color = "";

    switch (type) {
      case "Train":
        icon = "train";
        color = "#F2A64B";
        break;
      case "Flight":
        icon = "multiple_airports";
        color = "#5DA086";
        break;
      case "Car":
        icon = "local_taxi";
        color = "#797979";
        break;
      default:
        icon = "local_offer"; // Default icon if type doesn't match
        color = "#000000"; // Default color
    }

    return (
      <span className="material-symbols-outlined" style={{ color: color }}>
        {icon}
      </span>
    );
  };

  const columns = [
    columnHelper.accessor("slNo", {
      header: "Sl. No",
      cell: (info) => info.row.index + 1,
    }),
    columnHelper.accessor("packageName", {
      header: "PACKAGE NAME",
    }),
    columnHelper.accessor("packageType", {
      header: "PACKAGE TYPE",
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <PackageTypeIcon type={row.original.packageType} />
          <span>{row.original.packageType}</span>
        </div>
      ),
    }),
    columnHelper.accessor("source", {
      header: "SOURCE",
    }),
    columnHelper.accessor("destination", {
      header: "DESTINATION",
    }),
    columnHelper.accessor("pricePerHead", {
      header: "PRICE PER HEAD",
    }),
    columnHelper.accessor("actions", {
      header: "ACTIONS",
      cell: (info) => (
        <div className="flex gap-2">
          <button
            className="p-2 bg-gray-200 rounded-md"
            onClick={() => handleEditClick(info.row.original)}
          >
            <span className="material-symbols-outlined">edit</span>
          </button>
          <button
            onClick={() => handleDeleteClick(info.row.original)}
            className="p-2 bg-gray-200 text-red-500 rounded-md"
          >
            <span className="material-symbols-outlined">delete</span>
          </button>
          <button className="p-2 bg-gray-200 rounded-md">
            <span className="material-symbols-outlined">picture_as_pdf</span>
          </button>
        </div>
      ),
    }),
  ];

  const handleEditClick = (packageData) => {
    navigate("/add-packages", {
      state: {
        packageData: packageData,
        isEdit: true,
      },
    });
  };

  const handleFormSelection = (e, type) => {
    console.log(e, "eee");
    if (type == "city") {
      if (e.label === "All City") {
        setCity("");
      } else {
        setCity(e);
      }
    } else if (type == "packagetype") {
      if (e.label === "All Package") {
        setPackageValue("");
      } else {
        setPackageValue(e);
      }
    } else {
      if (e.label === "All Destination") {
        setDestination("");
      } else {
        setDestination(e);
      }
    }
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <div>
      <div className="block md:block lg:flex justify-between">
        <div>
          <h2 className="text-primary-black text-[28px] font-semibold">
            Packages
          </h2>
          <p className="text-[14px]">
            <span className="font-bold">Packages</span> /{" "}
            <span className="font-regular">Package List</span>
          </p>
        </div>
        <div className="flex mt-4 justify-end gap-4">
          <Button
            type="primary"
            label="Add Package"
            onClick={() => navigate("/add-packages")}
          />
        </div>
      </div>

      {/* Filter Fields */}
      <div className="flex flex-wrap md:flex-nowrap gap-4 mt-4">
        <input
          type="text"
          className="outline-none p-2 rounded-l-lg w-full h-[40px]"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => handleSearch(e)}
        />
        <div className="w-full md:w-auto">
          <FilterSelect
            className="w-full sm:w-auto"
            value={packageValue}
            options={packageOptions}
            onChange={(e) => handleFormSelection(e, "packagetype")}
            placeholder="Select Type..."
          />
        </div>
        <div className="w-full md:w-auto">
          <FilterSelect
            className="w-full sm:w-auto"
            value={city}
            options={cityOptions}
            onChange={(e) => handleFormSelection(e, "city")}
            placeholder="Select Source..."
          />
        </div>
        <div className="w-full md:w-auto">
          <FilterSelect
            className="w-full sm:w-auto"
            value={destination}
            options={destOptions}
            onChange={(e) => handleFormSelection(e, "destination")}
            placeholder="Select Destination..."
          />
        </div>
        <div className="w-full md:w-auto">
          <FilterSelect placeholder="Select Date..." />
        </div>
      </div>

      <div className="bg-white rounded-xl border-gray-300 mt-7">
        <Table columns={columns} data={packages} />
      </div>
      <ConfirmationModal
        isOpen={isDeleteModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        title="Delete Hotel"
        message={`Are you sure you want to delete the hotel "${deleteItem?.packagename}"?`}
      />
      <ToastContainer />
    </div>
  );
};

export default AllPackages;
