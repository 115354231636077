import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Select from "../customComponents/Select";
import { useEffect, useState } from "react";
import UploadHotel from "../customComponents/UploadHotel";
import RadioButton from "../customComponents/RadioButton";
import DatePicker from "../customComponents/DatePicker";
import { post } from "../utils/api";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../widgets/Loader";
import { toast } from "react-toastify";

const AddHotel = () => {
  const location = useLocation();
  const { hotelinfo, type } = location.state || {};
  const isEdit = !!hotelinfo?.hotelid;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedValue, setSelectedValue] = useState("single");
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedMealPlan, setSelectedMealPlan] = useState(null);
  const [showSeasonalFields, setShowSeasonalFields] = useState(false);
  const [hotelTypeOptions, setHotelTypeOptions] = useState([]);
  const [accommodationOptions, setAccommodationOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [mealPlanOptions, setMealPlanOptions] = useState([]);
  const [selectedHotelType, setSelectedHotelType] = useState(null);
  const [selectedAccommodation, setSelectedAccommodation] = useState(null);
  const [hotelName, setHotelName] = useState("");
  const [city, setCity] = useState("");
  const [pricePerHead, setPricePerHead] = useState("");
  const [seasonalPrice, setSeasonalPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchHotelType();
    fetchAccommodationType();
    fetchGrade();
    fetchMealPlan();
  }, []);

  useEffect(() => {
    if (isEdit) {
      prePopulateValue();
    }
  }, []);

  const prePopulateValue = async () => {
    try {
      setSelectedGrade(hotelinfo?.grade);
      setSelectedMealPlan(hotelinfo?.mealplan);
      setSelectedHotelType(hotelinfo?.hoteltype || "");
      setSelectedAccommodation(hotelinfo?.accommodationtype);

      setSeasonalPrice(hotelinfo?.season_priceperhead);
      setPricePerHead(hotelinfo?.priceperhead);
      setCity(hotelinfo?.city);
      setHotelName(hotelinfo?.hotelname);
      setStartDate(new Date(hotelinfo?.seasonstart));
      setEndDate(new Date(hotelinfo?.seasonend));
      setShowSeasonalFields(!!hotelinfo?.season_priceperhead);
    } catch (error) {}
  };

  const fetchHotelType = async () => {
    try {
      const payload = { type: "hoteltype" };
      const result = await post("/getOptionList", payload);
      const options = [
        ...result.data.map((list) => ({
          label: list.label,
          value: list.label,
          id: list.hoteltypeid,
        })),
      ];
      setHotelTypeOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAccommodationType = async () => {
    try {
      const payload = { type: "accommodationtype" };
      const result = await post("/getOptionList", payload);
      const options = [
        ...result.data.map((list) => ({
          label: list.label,
          value: list.label,
          id: list.accommodationtypeid,
        })),
      ];
      setAccommodationOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchGrade = async () => {
    try {
      const payload = { type: "grade" };
      const result = await post("/getOptionList", payload);
      const options = [
        ...result.data.map((list) => ({
          label: list.label,
          value: list.label,
          id: list.gradeid,
        })),
      ];
      setGradeOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchMealPlan = async () => {
    try {
      const payload = { type: "mealplan" };
      const result = await post("/getOptionList", payload);
      const options = [
        ...result.data.map((list) => ({
          label: list.label,
          value: list.label,
          id: list.mealplanid,
        })),
      ];
      setMealPlanOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleDateChange = (date, type) => {
    if (type == "startdate") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleFormSelection = (e, type) => {
    let value = e.value;
    if (type == "grade") {
      setSelectedGrade(value);
    } else if (type == "mealplan") {
      setSelectedMealPlan(value);
    } else if (type == "hoteltype") {
      setSelectedHotelType(value);
    } else if (type == "accommodationtype") {
      setSelectedAccommodation(value);
    }
  };

  const handleChange = (e, type) => {
    let value = e.target.value;
    if (type == "hotelname") {
      setHotelName(value);
    } else if (type == "city") {
      setCity(value);
    } else if (type == "priceperhead") {
      setPricePerHead(value);
    } else if (type == "seasonalprice") {
      setSeasonalPrice(value);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const payload = {
        gradeid: gradeOptions.filter((list) => list.label == selectedGrade)?.[0]
          ?.id,
        mealplanid: mealPlanOptions.filter(
          (list) => list.label == selectedMealPlan
        )?.[0]?.id,
        hoteltypeid: hotelTypeOptions.filter(
          (list) => list.label == selectedHotelType
        )?.[0]?.id,
        accommodationtypeid: accommodationOptions.filter(
          (list) => list.label == selectedAccommodation
        )?.[0]?.id,
        seasonstart: startDate,
        seasonend: endDate,
        hotelname: hotelName,
        city: city,
        priceperhead: pricePerHead,
        season_priceperhead: seasonalPrice,
        type: hotelinfo?.hotelid ? "update" : "insert",
        hotelid: hotelinfo?.hotelid,
      };
      await post("/addHotel", payload);
      toast.success("Hotel added successfully!");
      navigate("/hotels");
    } catch (error) {
      toast.error("An error occurred while adding the hotel."); // Show toast error message for any exceptions
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? <Loader /> : <></>}
      <div className="flex justify-between">
        <div>
          <h2 className="text-primary-black text-[24px] font-semibold">
            Hotels
          </h2>
          <p className="text-[12px]">
            <span className="font-bold">Master Data</span> /
            <span className="font-regular">Add Hotel</span>
          </p>
        </div>

        <div className="flex gap-4">
          {hotelinfo?.hotelid ? (
            <Button type="primary" label="Update" onClick={handleSave} />
          ) : (
            <Button type="primary" label="Save" onClick={handleSave} />
          )}
          <Button
            type="secondary"
            label="Cancel"
            onClick={() => navigate("/hotels")}
          />
        </div>
      </div>
      <Paper className="my-8 pt-6 pb-6">
        <h1 className="text-[16px] font-semibold">Upload Type</h1>
        <div className="mt-4 space-y-4">
          <RadioButton
            name="customRadio"
            value="single"
            checked={selectedValue === "single"}
            onChange={handleRadioChange}
            label="Single Upload"
          />
          <RadioButton
            name="customRadio"
            value="bulk"
            checked={selectedValue === "bulk"}
            onChange={handleRadioChange}
            label="Bulk Upload"
            className={"ml-4"}
          />
        </div>
        {selectedValue === "single" && (
          <>
            <Paper className="my-8 border">
              <h1 className="text-[20px] font-bold mb-4">Hotel Details</h1>
              <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Hotel Type
                  </label>
                  <Select
                    placeholder="Select Type..."
                    options={hotelTypeOptions}
                    onChange={(e) => handleFormSelection(e, "hoteltype")}
                    value={hotelTypeOptions.find(
                      (option) => option.value === selectedHotelType
                    )}
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold">
                    Hotel Name
                  </label>
                  <InputText
                    placeholder="Enter Hotel Name..."
                    value={hotelName}
                    onChange={(e) => handleChange(e, "hotelname")}
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    City (Destination)
                  </label>
                  <InputText
                    placeholder="Enter City..."
                    onChange={(e) => handleChange(e, "city")}
                    value={city}
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Accommodation Type
                  </label>
                  <Select
                    options={accommodationOptions}
                    value={accommodationOptions.find(
                      (option) => option.value === selectedAccommodation
                    )}
                    onChange={(e) =>
                      handleFormSelection(e, "accommodationtype")
                    }
                    placeholder="Select Accommodation..."
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold">Grade</label>
                  <Select
                    options={gradeOptions}
                    value={gradeOptions.find(
                      (option) => option.value === selectedGrade
                    )}
                    placeholder="Select Grade..."
                    onChange={(e) => handleFormSelection(e, "grade")}
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold">Meal Plan</label>
                  <Select
                    options={mealPlanOptions}
                    value={mealPlanOptions.find(
                      (option) => option.value === selectedMealPlan
                    )}
                    placeholder="Select Meal Plan..."
                    onChange={(e) => handleFormSelection(e, "mealplan")}
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold">
                    Price per head (INR)
                  </label>
                  <InputText
                    placeholder="Enter Price..."
                    onChange={(e) => handleChange(e, "priceperhead")}
                    value={pricePerHead}
                  />
                </div>
              </div>

              <div className="col-span-4 flex items-center gap-2 mt-4 mb-4">
                <input
                  type="checkbox"
                  id="seasonalPrice"
                  checked={showSeasonalFields}
                  onChange={(e) => setShowSeasonalFields(e.target.checked)}
                />
                <label
                  htmlFor="seasonalPrice"
                  className="text-[16px] font-semibold"
                >
                  Seasonal Price
                </label>
              </div>
              {showSeasonalFields && (
                <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  <div>
                    <label className="text-[16px] font-semibold">
                      Season Start Date
                    </label>
                    <DatePicker
                      selectedDate={startDate}
                      onDateChange={(date) =>
                        handleDateChange(date, "startdate")
                      }
                      placeholderText="Start Date"
                      className="w-full"
                    />
                  </div>
                  <div>
                    <label className="text-[16px] font-semibold">
                      Season End Date
                    </label>
                    <DatePicker
                      selectedDate={endDate}
                      onDateChange={(date) => handleDateChange(date, "enddate")}
                      placeholderText="End Date"
                      className="w-full "
                    />
                  </div>
                  <div>
                    <label className="text-[16px] font-semibold">
                      Seasonal Price per Head (INR)
                    </label>
                    <InputText
                      placeholder="Enter Seasonal Price..."
                      onChange={(e) => handleChange(e, "seasonalprice")}
                      value={seasonalPrice}
                    />
                  </div>
                </div>
              )}
            </Paper>
          </>
        )}
        {selectedValue === "bulk" && (
          <Paper className="my-8 border">
            <h1 className="text-[20px] font-bold mb-4">Upload File</h1>
            <UploadHotel />
          </Paper>
        )}
      </Paper>
    </div>
  );
};

export default AddHotel;
